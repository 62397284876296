import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Flex, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import SectionWithBackground from '../components/section-with-background'
import Container from '../components/container'
import SectionTitle from '../components/section-title'
import ButtonLink from '../components/button-link'
import Gallery from '../components/gallery'

const GaleriePage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { pageType: { eq: "gallery" } }) {
        frontmatter {
          title
          subtitle
          galleries {
            name
            featuredImages {
              childImageSharp {
                original {
                  src
                }
                fluid(quality: 85, maxWidth: 320) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "landscape.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contactImage: file(relativePath: { eq: "landscape.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { title, subtitle, galleries } = data.markdownRemark.frontmatter
  const backgroundImage = data.backgroundImage.childImageSharp.fluid
  const contactImage = data.contactImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={title} />

      <Hero
        backgroundImage={backgroundImage}
        title={title}
        subtitle={subtitle}
      />

      <Section variant="light">
        <Container>
          {galleries && galleries.map((gallery) => {
            const { name, featuredImages } = gallery

            return (
              <Box mb={4}>
                <SectionTitle as="h2">{name}</SectionTitle>

                {featuredImages && <Gallery images={featuredImages} />}
              </Box>
            )
          })}
        </Container>
      </Section>

      <SectionWithBackground fluid={contactImage} variant="dark" hasOverlay>
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2" color="white">
              Zaujal Vás některý z pozemků nebo projektů?
            </SectionTitle>

            <Text
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
                mb: 4,
              }}
            >
              Neváhejte nás kontaktovat telefonicky či e-mailem.
            </Text>

            <ButtonLink variant="white" to="/kontakt">
              Kontaktujte nás
            </ButtonLink>
          </Flex>
        </Container>
      </SectionWithBackground>
    </Layout>
  )
}

export default GaleriePage
